<script setup lang="ts">
const { t } = useI18n();

const scroll = () => {
  document.getElementById('products')?.scrollIntoView({ behavior: 'smooth' });
};
</script>

<template>
  <section class="section-welcome section-bottom-shadow relative">
    <NuxtImg
      src="/images/main-background.jpg"
      width="1800"
      height="1201"
      sizes="lg:100vw xl:100vw xxl:100vw"
      format="webp"
      :alt="t('subtitle')"
      class="page-image-preview"
    />
    <div class="section-welcome__content px-4 md:px-5 z-1">
      <div class="section-welcome__logoContainer">
        <NuxtImg
          src="/logo.svg"
          :alt="t('logo_alt')"
          width="600"
          height="600"
          class="section-welcome__logo"
        />
      </div>

      <div class="container mx-auto flex flex-col items-center">
        <h1 class="section-welcome__title mt-0 uppercase">
          {{ t('title') }}
        </h1>
        <h2 class="section-welcome__description">
          {{ t('subtitle') }}
        </h2>
        <div class="section-welcome__links">
          <Button
            @click="scroll"
            :label="t('more')"
            rounded
            size="large"
            class="px-8 sm:px-12 sm:py-5"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<i18n>
en:
  title: Erotic games for couple
  subtitle: Unforgettable sex with your soulmate
  more: Find out more
  logo_alt: Sex Play website logo

ru:
  title: Эротические игры для двоих
  subtitle: Незабываемый секс со своей второй половинкой
  more: Узнать больше
  logo_alt: Логотип сайта Sex Play
</i18n>

<style lang="scss">
.section-welcome {
  min-height: 92vh;
  color: white;
  background-color: rgb(16, 16, 20);
  position: relative;
  display: flex;
  flex-direction: column;

  &__content {
    flex: 1;
    padding: var(--header-height) 24px 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    position: relative;
    background-color: rgba(14, 1, 29, 0.7);
  }

  &__logoContainer {
    opacity: 0;
    animation: show 0.5s 0.2s ease forwards;
    width: 140px;
    height: 140px;
    margin-bottom: 12px;

    @media (min-width: 640px) {
      width: 160px;
      height: 160px;
    }

    @media (min-width: 1600px) {
      width: 192px;
      height: 192px;
    }
  }

  &__logo {
    display: block;
    transform-origin: 50% 50%;
    animation: animated-logo 2.5s infinite linear;
    width: 100%;
    height: 100%;
  }

  &__title {
    text-align: center;
    font-size: 28px;
    animation: show 0.5s 0.7s ease forwards;
    opacity: 0;
    margin-bottom: 2rem;
    @media (min-width: 640px) {
      font-size: 36px;
    }
    @media (min-width: 1024px) {
      font-size: 56px;
    }
    @media (min-width: 1366px) {
      font-size: 62px;
    }
    @media (min-width: 1920px) {
      font-size: 84px;
    }
  }
  &__description {
    text-align: center;
    font-size: 16px;
    margin-top: 0;
    animation: show 0.5s 1.2s ease forwards;
    opacity: 0;
    @media (min-width: 640px) {
      font-size: 18px;
      white-space: pre-line;
    }
    @media (min-width: 1024px) {
      font-size: 24px;
    }
    @media (min-width: 1366px) {
      font-size: 28px;
    }
    @media (min-width: 1920px) {
      font-size: 32px;
    }
  }
  &__links {
    display: flex;
    gap: 16px;
    margin-top: 32px;
    margin-bottom: 32px;
    flex-direction: column;
    align-items: center;
    animation: show 0.5s 1.7s ease forwards;
    opacity: 0;
    @media (min-width: 440px) {
      gap: 32px;
      flex-direction: row;
    }
    @media (min-width: 1600px) {
      margin-top: 54px;
    }
    a {
      text-decoration: none;
    }
  }
  @keyframes show {
    0% {
      transform: translateY(40px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes animated-logo {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }
}
</style>
