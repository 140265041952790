<script lang="ts" setup>
const { t } = useI18n();

const COMMENTARIES = Array.from({ length: 3 }, (_, i) => ({
  text: t(`commentaries.${i}.text`),
  name: t(`commentaries.${i}.name`),
  alt: t(`commentaries.${i}.alt`),
}));
const PARAMETERS = [
  {
    animation: {},
    src: '/images/pictures/female-avatar-1.svg',
  },
  {
    animation: { delay: 150, noDelayOnMobile: 992 },
    src: '/images/pictures/female-avatar-2.svg',
  },
  {
    animation: { delay: 300, noDelayOnMobile: 992 },
    src: '/images/pictures/male-avatar-1.svg',
  },
];
</script>

<template>
  <div class="commentariesSection text-center px-4 md:px-5 py-16">
    <h2
      v-show-on-visible
      class="text-2xl md:text-5xl text-800 text-center mt-0 mb-14 md:mb-8"
    >
      {{ t('title') }}
    </h2>
    <div
      class="commentariesSection__content container mx-auto flex flex-col lg:flex-row items-start gap-20 lg:gap-6 xl:gap-4 pt-8"
    >
      <Card
        v-for="(commentary, index) in COMMENTARIES"
        :key="commentary.name"
        v-show-on-visible="PARAMETERS[index].animation"
        class="shadow-2 neon-border relative flex-1"
      >
        <template #content>
          <div
            class="!absolute left-50 z-3 neon-border bg-surface-600"
            style="
              margin-left: -50px;
              top: -50px;
              left: 50%;
              border-radius: 50%;
            "
          >
            <img
              :src="PARAMETERS[index].src"
              :alt="commentary.alt"
              width="100"
              height="100"
              class="block"
            />
          </div>
          <div class="pt-12 relative">
            <p
              class="text-900 font-italic leading-6 text-sm m-0 pb-4 border-solid border-0 border-b border-white surface-border"
            >
              “{{ commentary.text }}”
            </p>
            <div class="text-900 font-semibold leading-6 mt-4">
              {{ commentary.name }}
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<i18n src="~/locales/commentaries.yaml"></i18n>
<i18n>
en:
  title: Reviews about us

ru:
  title: Отзывы о нас
</i18n>

<style lang="scss">
.commentariesSection {
  background-color: var(--p-surface-700);
}
</style>
