<script lang="ts" setup>
const { t } = useI18n();
</script>

<template>
  <section
    class="relative bg-black px-4 md:px-5 py-16 lg:py-20 xl:py-28"
    id="products"
  >
    <NuxtImg
      class="page-image-preview hidden md:block"
      src="/images/products-background.jpg"
      width="1700"
      height="1133"
      sizes="lg:100vw xl:100vw xxl:100vw"
      :alt="t('preview_alt')"
      format="webp"
      loading="lazy"
    />
    <div
      class="page-overflow-7 section-top-shadow section-bottom-shadow hidden md:block"
    />

    <h2
      v-show-on-visible
      class="text-2xl md:text-5xl text-800 text-center mb-16 mt-8 relative z-1"
    >
      {{ t('title') }}
    </h2>
    <div
      class="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-4 z-2 relative"
    >
      <Card
        v-show-on-visible
        class="mb-3 h-full neon-border"
        :pt="{
          body: 'h-full',
          footer: 'flex flex-col sm:flex-row justify-end gap-3 mt-auto',
        }"
      >
        <template #content>
          <div class="flex items-start">
            <span
              class="inline-flex rounded-full items-center justify-center mr-3"
              style="
                flex: 0 0 38px;
                width: 38px;
                height: 38px;
                background-color: var(--p-teal-600);
              "
            >
              <i class="pi pi-heart text-xl" />
            </span>
            <h3 class="text-900 font-medium text-lg md:text-2xl my-0">
              {{ t('tasks_subtitle') }}
            </h3>
          </div>
          <p
            class="text-900 mt-4 mb-2 text-sm md:text-lg font-medium leading-6"
          >
            {{ t('tasks_description') }}
            <br />
            <br />
            {{ t('tasks_rules') }}
          </p>
        </template>

        <template #footer>
          <NuxtLink to="/about-fants" class="w-full sm:w-fit">
            <Button
              rounded
              outlined
              size="large"
              type="button"
              class="w-full sm:w-fit"
            >
              {{ t('find_out_more') }}
            </Button>
          </NuxtLink>
          <NuxtLink to="/game/settings" class="w-full sm:w-fit">
            <Button rounded size="large" type="button" class="w-full sm:w-fit">
              {{ t('start_the_game') }}
            </Button>
          </NuxtLink>
        </template>
      </Card>

      <Card
        v-show-on-visible="{ delay: 150, noDelayOnMobile: 768 }"
        class="mb-3 h-full neon-border"
        :pt="{
          body: 'h-full',
          footer: 'flex flex-col sm:flex-row justify-end gap-3 mt-auto',
        }"
      >
        <template #content>
          <div class="flex items-start">
            <span
              class="inline-flex rounded-full items-center justify-center mr-3"
              style="
                flex: 0 0 38px;
                width: 38px;
                height: 38px;
                background-color: var(--p-purple-600);
              "
            >
              <i class="pi pi-question text-xl" />
            </span>
            <h3 class="text-900 font-medium text-lg md:text-2xl my-0">
              {{ t('interests_subtitle') }}
            </h3>
          </div>
          <div
            class="text-900 mt-4 mb-2 text-sm md:text-lg font-medium leading-6"
          >
            <p class="m-0">
              {{ t('interests_description') }}
            </p>
            <ul class="my-2 pl-3">
              <li>{{ t('interests_steps.0') }}</li>
              <li>{{ t('interests_steps.1') }}</li>
              <li>{{ t('interests_steps.2') }}</li>
              <li>{{ t('interests_steps.3') }}</li>
            </ul>
          </div>
        </template>

        <template #footer>
          <NuxtLink to="/about-fants#interests" class="w-full sm:w-fit">
            <Button
              rounded
              outlined
              size="large"
              type="button"
              class="w-full sm:w-fit"
            >
              {{ t('find_out_more') }}
            </Button>
          </NuxtLink>
          <NuxtLink to="/interests" class="w-full sm:w-fit">
            <Button rounded size="large" type="button" class="w-full sm:w-fit">
              {{ t('go_to_questions') }}
            </Button>
          </NuxtLink>
        </template>
      </Card>
    </div>
  </section>
</template>

<i18n>
en:
  title: What you will find on our website
  preview_alt: Uncover new interests of your partner
  tasks_subtitle: Erotic tasks for a couple
  tasks_description: Erotic tasks for a couple - a game designed for those who want to bring spark and passion into their
    sex life, try something new in bed, or just diversify their intimate life.
  tasks_rules: 'The essence of the game is simple: in turn, each opens a card and completes the task. It is important that
    at this moment your partner does not see the tasks, so the game will be more interesting, and the sensations will be
    brighter!'
  interests_subtitle: Uncover new interests of your partner
  interests_description: 'We will help you find new hot ideas for sex. How it works:'
  interests_steps:
    - Both partners connect their accounts;
    - Both partners answer the same sex questions «Yes», «Maybe», «No»;
    - If both partners answered «Yes» or «Maybe», then the question is shown as a match;
    - Any idea that is answered «No» by any of the partners will be hidden.
  find_out_more: More
  start_the_game: Start the game
  go_to_questions: Go to questions

ru:
  title: Что вы у нас найдете
  preview_alt: Раскрыть новые интересы партнера
  tasks_subtitle: Эротические фанты и задания
  tasks_description: Игра в эротические фанты — для желающих внести огонек и страсть в свою сексуальную жизнь, опробовать
    что-то новое в постели, либо просто разнообразить интимную жизнь.
  tasks_rules: 'Суть игры проста: поочередно каждый открывает фант и выполняет задание. Важно, чтобы в этот момент Ваша
    половинка не видела задания, так игра будет интересней, а ощущения ярче!'
  interests_subtitle: Раскрыть новые интересы партнера
  interests_description: 'Поможем найти новые горячие идеи для секса. Как это работает:'
  interests_steps:
    - Оба партнера соединяют свои аккаунты;
    - Оба партнера отвечают одинаковые секс вопросы «Да», «Может», «Нет»;
    - Если оба партнера ответили «Да» или «Может», то вопрос показан как совпадение;
    - Любая идея на которую ответил «Нет» любой из партнеров, будет спрятана.
  find_out_more: Подробнее
  start_the_game: Начать игру
  go_to_questions: К вопросам
</i18n>
